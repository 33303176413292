require(['jquery'], function ($) {

  $('.product_pricetag').each(function () { $(this).text('$') });

  if ($("#mini-cart").length > 0) {
    $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
  }

  $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");
  if ($(".logo-wrapper").length > 0) {
    $('.logo-wrapper').append("<div class='show-below-960 right nav-search'>" + $(".search-wrapper").html() + "</div>");
    if ($("#mini-cart").length > 0) {
      $('.logo-wrapper').prepend("<div class='show-below-960 right nav-cart'>" + $("#mini-cart").html() + "</div>");
    }
  }

  if ($(".variant-option").length > 0) {
    if ($(".variant-option .swatches-con .swatch img").length > 0) {
      $(".variant-option .swatches-con").css('display', 'block');
    }
  }

  let topSize = 102;

  if ($(window).width() < 960) {
    topSize = 41;
  }

  $(window).on('resize', onResize);

  $(document).ready(function () {
    onResize();
  });

  var onResize = function () {
    if ($(window).width() < 960) {

      topSize = 41;

      $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
          e.preventDefault();
          $(".nav-search").addClass("fly-out");
          $(".nav-search .form-search .input-text").focus();
          $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
      });
    }
    else {
      topSize = 102;
    }
  };

  $(document).on('click', '.nav-search .button-close', function (e) {
    e.preventDefault();
    $(".nav-search").removeClass("fly-out");
  });

  $('.sidebar-category .block .block-title, .sidebar-filter .block .block-title').click(function () {
    $(this).toggleClass('open');
  });

  floatingHeader(topSize);

  /* floating header */
  $(window).scroll(function () {
    floatingHeader(topSize);
  });

  if ($('.page-type-category').length) {
    setTimeout(() => {
      const itemHeight = $('ul#listing-ul li.item:first-of-type').css('height');
      $('body').append('<style>.category-products-grid.hover-effect .item:hover{height:' + itemHeight + ' !important;}</style>');
    }, 1000);

  }
});

function floatingHeader(headerHeight) {
  if ($(this).scrollTop() >= headerHeight) {
    if (!$('#header').hasClass('floating')) {
      $('#header').addClass('floating');
    }
  }
  if ($(this).scrollTop() < headerHeight) {
    if ($('#header').hasClass('floating')) {
      $('#header').removeClass('floating');
    }
  }
}